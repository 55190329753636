.line-loader-container{
	position: fixed;
    top: 0px;
    width: 100%;
    left: 0px;
	z-index: 9999;
}

.line-loader {
  height: 4px;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: #ddd;
}
.line-loader:before{
  display: block;
  position: absolute;
  content: "";
  left: -200px;
  width: 200px;
  height: 4px;
  background-color: #FFC107;
  animation: lineloading 2s linear infinite;
}

.line-loader-text-container{
	z-index: 9999;
	position: fixed;
    top: 7px;
    width: 115px;
    margin-right: auto;
    margin-left: auto;
    left: 47%;
    background: #fff38e;
    text-align: center;
    border: 1px solid black;
    padding: 4px;
    font-size: 14px;
    font-weight: 600;
}

@keyframes lineloading {
    from {left: -200px; width: 30%;}
    50% {width: 30%;}
    70% {width: 70%;}
    80% { left: 50%;}
    95% {left: 120%;}
    to {left: 100%;}
}